<template>

  <video :src="post.fileUrl" playsinline preload="auto" ref="videoPlayer"
    @click="togglePlay"></video>

  <div class="controls">
    <ProgressBar :percent="progress" @select-perc="setPosition"/>
  </div>
  <div v-if="!isPlaying || isLoading" class="playOverlay" @click="togglePlay">
    <LoadingIndicator v-if="isLoading"/>

    <svg v-if="!isLoading" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
          fill="#FFFFFF"></path>
      </g>
    </svg>
  </div>

</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { Post } from "../services/redditService";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ProgressBar from "@/components/ProgressBar.vue";

const props = defineProps<{
  post: Post,
  autoscroll?: Boolean
}>()

const emit = defineEmits(['ended'])

const videoPlayer = ref<HTMLVideoElement>();

const isPlaying = ref(false);
const isLoading = ref(true);
const progress = ref(0);


onMounted(() => {
  if (videoPlayer.value) {
    videoPlayer.value.addEventListener('play', () => isPlaying.value = true);
    videoPlayer.value.addEventListener('pause', () => isPlaying.value = false);
    videoPlayer.value.addEventListener('canplay', () => isLoading.value = false);
    //videoPlayer.value.addEventListener('metadata', () => videoDuration.value = videoPlayer.value!.duration);
    videoPlayer.value.addEventListener('timeupdate', handleTimer);
    videoPlayer.value.addEventListener('ended', handleVideoEnd);
  }
})

onBeforeUnmount(() => {
  videoPlayer.value!.removeEventListener('timeupdate', handleTimer);
})

const handleTimer = () => {
  progress.value = videoPlayer.value!.currentTime / videoPlayer.value!.duration * 100;
}

const handleVideoEnd = () => {
  emit('ended');
  if (!props.autoscroll) play();
}

const play = () => {
  videoPlayer.value!.play();
}
const stop = () => {
  videoPlayer.value!.pause();
}

const togglePlay = () => {
  if (isPlaying.value) {
    stop()
  } else {
    play()
  }
}

const setPosition = (perc: number) => {
  videoPlayer.value!.currentTime = perc / 100 * videoPlayer.value!.duration
}


defineExpose({
  play,
  stop
})

</script>

<style lang="scss" scoped>
video {
  width: 100vw;
  height: 100vh;
}
.controls {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 20px;
  bottom: 60px; //calc(5px + env(safe-area-inset-bottom));
  height: 10px;
}

.playOverlay {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: #00000066;

  svg {
    height: 60px;
    filter: drop-shadow(0 0 5px rgb(0 0 0 / 0.4));
  }
}
</style>
