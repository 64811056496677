<template>
  <div class="progress-bar" @click="handleClick" @mouseenter="cursorVisible = true" @mouseleave="cursorVisible = false"
    @mousemove="moveCursor" ref="bar">
    <div class="outer">
      <div class="inner" :style="{width: perc}"></div>
      <div class="cursor" :style="{left: cursorLeft + 'px', display: cursorVisible ? 'block' : 'none'}"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref} from 'vue';

const bar = ref<HTMLDivElement>();
const props = defineProps<{
  percent?: number,
  timer?: number | null
}>()


const cursorVisible = ref(false)
const cursorLeft = ref(0)
const tinmerPerc = ref(0)

const emit = defineEmits(['selectPerc'])

const perc = computed(() => {
  const p = props.timer ? tinmerPerc.value : props.percent;
  return `${p}%`;
});

const handleClick = (e:MouseEvent) => {
  const perc = e.offsetX / bar.value!.offsetWidth * 100;
  emit('selectPerc', perc);
}

const moveCursor = (e:MouseEvent) => {
  if (cursorVisible.value) {
    cursorLeft.value = e.offsetX
  }
}
const cursorStyle = computed(() => {
  return {
    left: cursorLeft,
    disaplay: cursorVisible.value
  }
})

// Timer
let interval: number;
const timerIntervalTime = 10;
onMounted(() => {
  tinmerPerc.value = 0
  if (props.timer) {
    interval = setInterval(() => {
      tinmerPerc.value = tinmerPerc.value + (100 / (props.timer! / timerIntervalTime) )
      if(tinmerPerc.value >= 100) tinmerPerc.value = 0;
    }, timerIntervalTime)
  }
})

onBeforeUnmount(() => {
  if (interval) clearInterval(interval);
})


</script>

<style lang="scss" scoped>
  $height: 2px;

  .progress-bar {
    padding: 20px 0;

    .outer {
      height: $height;
      background-color: #FFFFFF20;
      position: relative;
    }

    .inner {
      position: absolute;
      left: 0;
      top: 0;
      height: $height;
      background-color: rgb(255, 0, 85);
    }
    .cursor {
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: $height;
      background-color: #FFF
    }
  }

</style>
