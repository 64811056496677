<template>
  <nav :class="{open: menuIsOpen}" @click="menuIsOpen = false">

    <input class="search" placeholder="Search" v-model="search" @click.prevent.stop=""/>

    <div class="cont">

      <ul class="bookmarks">
        <li v-for="b in filteredItems" :key="b" @click="selectBookmark(b)"
          :class="{selected: selectedBookmarks.includes(b)}">{{ b }}</li>
      </ul>
    </div>
    <div class="icons">
      <router-link to="/">
        <svg viewBox="-2 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <title>home [#ffffff]</title>
            <desc>Created with Sketch.</desc>
            <defs> </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Dribbble-Light-Preview" transform="translate(-381.000000, -720.000000)" fill="#ffffff">
                <g id="icons" transform="translate(56.000000, 160.000000)">
                  <path
                    d="M339.875,578.013 L336.6875,578.013 L336.6875,574.013 L330.3125,574.013 L330.3125,578.013 L327.125,578.013 L327.125,568.799 L333.489375,562.809 L339.875,568.819 L339.875,578.013 Z M341.94475,568.013 L333.47025,560 L325,567.999 L325,580.013 L332.4375,580.013 L332.4375,576.013 L334.5625,576.013 L334.5625,580.013 L342,580.013 L342,579.983 L342,568.013 L341.94475,568.013 Z"
                    id="home-[#ffffff]"> </path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </router-link>
      <a href="#" @click.prevent="openRandomFeed">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <g fill="#ffffff">
              <path
                d="M5 4a1 1 0 000 2h.01a1 1 0 000-2H5zM7 8a1 1 0 011-1h.01a1 1 0 010 2H8a1 1 0 01-1-1zM11.01 10a1 1 0 100 2h.01a1 1 0 100-2h-.01z">
              </path>
              <path fill-rule="evenodd"
                d="M3.25 1A2.25 2.25 0 001 3.25v9.5A2.25 2.25 0 003.25 15h9.5A2.25 2.25 0 0015 12.75v-9.5A2.25 2.25 0 0012.75 1h-9.5zM2.5 3.25a.75.75 0 01.75-.75h9.5a.75.75 0 01.75.75v9.5a.75.75 0 01-.75.75h-9.5a.75.75 0 01-.75-.75v-9.5z"
                clip-rule="evenodd"></path>
            </g>
          </g>
        </svg>
      </a>
    </div>
  </nav>
  <!--<a href="#" class="navButton" @click.prevent="menuIsOpen = !menuIsOpen">
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path fill="#ffffff" fill-rule="evenodd"
          d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z">
        </path>
      </g>
    </svg>
  </a>
-->
  <router-view />
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useBookmarks } from './composables/useBookmarks';
  import { useRouter } from 'vue-router';

  const { bookmarks, selectedBookmarks, selectBookmark, selectRandom } = useBookmarks()
  const router = useRouter()

  const menuIsOpen = ref(false);
  const search = ref('');

  const filteredItems = computed(() => {
    return search.value.length > 0
      ? bookmarks.value.filter((s: string) => s.toLowerCase().indexOf(search.value.toLocaleLowerCase()) > -1)
      : bookmarks.value
  });

  const openRandomFeed = () => {
    selectRandom();
    router.push({name: 'feed'});
  }

</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  background-color: #090909;

  scroll-snap-type: y mandatory;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #fff;
}

.navButton {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  z-index: 10;
  transition: all 200ms linear;

  color: #FFFFFF20;
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 2rem;

  &:hover {
    opacity: .7;
  }
}

nav {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #2c3e50;
  width: 320px;
  transform: translateX(-320px);
  transition: all 200ms ease-out;
  overflow-y: auto;

  &.open {
    transform: translateX(0);
  }

  .search {
    margin: 16px 10px 0;
    border: 0 solid #FFFFFF;
    border-bottom-width: 2px;
    color: #FFFFFF;
    background-color: transparent;
    font-size: 1.8rem;
    font-weight: bold;
    box-sizing: border-box;
    width: 80%;

    &::placeholder {
      color: #ffffff80;
    }
  }

  .cont {
    position: absolute;
    top: 60px;
    left: 10px;
    right: 10px;
    bottom: 80px;
    overflow-y: auto;
  }

  .icons {
    position: absolute;
    bottom: 16px;
    right: 10px;
    left: 80px;

    a {
      padding: 0 10px;

      &:hover {
        opacity: .7;
      }

      svg {
        height: 40px
      }
    }
  }

  a {
    font-weight: bold;
    color: #FFF;

    &.router-link-exact-active {
      color: #DDD;
    }
  }

  .bookmarks {
    padding-left: 0;
    li {
      list-style-type: none;
      cursor: pointer;
      margin-bottom: .2rem;;
      transition: all 200ms linear;

      &:hover {
        opacity: .8;
      }

      &.selected {
        font-weight:  bold;
      }
    }
  }

}
</style>
