<template>
  <FeedViewer v-show="selectedUser == ''"
    :redditUrl="selectedBookmarks[0]"
    name="MainFeed"
    :autoscroll="isAutoscroll"
    @showUserFeed="showUserFeed"
    @end-of-list="isAutoscroll = false" />
  <div class="actionButtons stacked">

    <a href="#" @click.prevent="toggleAutoscroll">
      <svg v-if="!isAutoscroll" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
            fill="#FFFFFF"></path>
        </g>
      </svg>
      <svg v-if="isAutoscroll" viewBox="-1 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" fill="#ffffff" stroke="#ffffff">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <title>pause [#ffffff]</title>
          <desc>Created with Sketch.</desc>
          <defs> </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Dribbble-Light-Preview" transform="translate(-227.000000, -3765.000000)" fill="#ffffff">
              <g id="icons" transform="translate(56.000000, 160.000000)">
                <path
                  d="M172,3605 C171.448,3605 171,3605.448 171,3606 L171,3612 C171,3612.552 171.448,3613 172,3613 C172.552,3613 173,3612.552 173,3612 L173,3606 C173,3605.448 172.552,3605 172,3605 M177,3606 L177,3612 C177,3612.552 176.552,3613 176,3613 C175.448,3613 175,3612.552 175,3612 L175,3606 C175,3605.448 175.448,3605 176,3605 C176.552,3605 177,3605.448 177,3606"
                  id="pause-[#ffffff]"> </path>
              </g>
            </g>
          </g>
        </g>
      </svg>

    </a>

    <a href="#" @click.prevent="selectRandom">
      <svg fill="#ffffff" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M504.971 359.029c9.373 9.373 9.373 24.569 0 33.941l-80 79.984c-15.01 15.01-40.971 4.49-40.971-16.971V416h-58.785a12.004 12.004 0 0 1-8.773-3.812l-70.556-75.596 53.333-57.143L352 336h32v-39.981c0-21.438 25.943-31.998 40.971-16.971l80 79.981zM12 176h84l52.781 56.551 53.333-57.143-70.556-75.596A11.999 11.999 0 0 0 122.785 96H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12zm372 0v39.984c0 21.46 25.961 31.98 40.971 16.971l80-79.984c9.373-9.373 9.373-24.569 0-33.941l-80-79.981C409.943 24.021 384 34.582 384 56.019V96h-58.785a12.004 12.004 0 0 0-8.773 3.812L96 336H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h110.785c3.326 0 6.503-1.381 8.773-3.812L352 176h32z">
          </path>
        </g>
      </svg>
    </a>

    <RouterLink to="/">
      <svg fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M21.555,8.168l-9-6A1,1,0,0,0,12,2h0a1,1,0,0,0-.554.168l-9,6A1,1,0,0,0,3,10H4V21a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V10h1a1,1,0,0,0,.556-1.832ZM18,20H6V8.2l6-4,6,4Z">
          </path>
        </g>
      </svg>
    </RouterLink>

  </div>

  <div class="userView" v-show="selectedUser!=''">
    <FeedViewer :redditUrl="selectedUser" name="UserFeed" :filter-multi-user-posts="true" />
    <a class="actionButtons" href="#" @click.prevent="selectedUser=''">

      {{ selectedUser }}
      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path fill="#ffffff"
            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z">
          </path>
        </g>
      </svg>
    </a>
  </div>
</template>

<script lang="ts" setup>

import FeedViewer from '@/components/FeedViewer.vue';
import { useBookmarks } from '@/composables/useBookmarks';
import { ref } from 'vue';

const { selectedBookmarks, selectRandom } = useBookmarks()

const selectedUser = ref('');

const showUserFeed = (payload:string) => {
  console.log('ShowUser: ', payload)
  selectedUser.value = 'user/' + payload;
}

const isAutoscroll = ref(false);
const toggleAutoscroll = () => {
  isAutoscroll.value = !isAutoscroll.value;
}

</script>

<style lang="scss" scoped>
  .userView {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    background-color: #000000;
  }
  .actionButtons {
    z-index: 5;
    position: fixed;
    bottom: 70px;
    right: 10px;

    color: #FFFFFF60;
    text-decoration: none;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    text-shadow: 0 0 8px rgby(0,0,0,.4);

    &.stacked {
      flex-direction: column;
      justify-content: end;
      gap: 20px;
    }

    a {
      display: block;
      opacity: .7;
      transition: all .1s linear;
      &:hover {
        opacity: 1;
      }
      padding: 5px;
    }

    svg {
      height: 30px;
      filter: drop-shadow(0 5px rgb(0 0 0 / 0.4));
      margin: 0 10px;

      &:hover {
          opacity: .7;
        }
    }
  }
</style>

