import { Ref, ref } from 'vue';
import subreddits from '@/data/subreddits.json'

const bookmarks: Ref<string[]> = ref(subreddits)
/*[
  'r/gonewild',
  'r/Innie',
  'r/cumsluts',
  'r/Upskirt',
  'r/panties',
  'r/grool',
  '/r/cameltoe',
  'r/CuteAndSexy',
  'r/SexyChicksInPics',
  'r/BustyPetite',
  'r/gentlemanboners',
  'r/BeautifulFemales',
  'r/ginger',
  'r/stockings',
  'r/TinyTits',
  'r/stockingsgw',
  'r/LingerieGW',
  'r/lingerie',
  'r/lingeriewomen',
  'r/NudeNonNude',
  'r/Nude_Selfie',
  'r/PetiteGoneWild',
  'r/pussy_pussy_pussy',
  'r/GodPussy',
  'r/shavedpussy',
  'r/pussy',
  'r/ShavedPussyPorn',
  'r/OnOff',
  'r/jilling',
  'r/GoneWildCurvy',
  'r/voluptuous',
  'r/PAWG',
  'r/BurstingOut',
  'r/GoneWild18',
  'r/gettingherselfoff',
  'r/Orgasms',
  'r/forcedorgasms',
  'r/Ersties',
  'r/Fingering',
  'r/LegalTeens',
  'r/tiktokthots',
  'r/GirlsFinishingTheJob',
  'r/VoyeurFlash',
  'r/asstastic',
  'r/workgonewild',
  'r/girlsinyogapants',
  'r/anal',
  'r/TittyDrop',
  'r/SmallCutie ',
  'r/GirlswithNeonHair',
  'r/girlsinleggings',
  'r/RealJilling',
  'r/CumshotConnoisseurs',
  'r/FitNakedGirls',
  'r/dreamedwet',
  'r/GirlsWithGirls',
  'r/TeaseAndDenial',
  'r/PublicTease',
  'r/PantyTease',
  'r/BralessTease',
  'r/teasing',
  'r/TheArtOfTheTease',
  'r/needysluts ',
  'r/DontTeaseMeBro',
  'r/WickedWeaselTease ',
  'r/iamatease ',
  'r/GymGirlsNSFW ',
  'r/Striptease',
  'r/chubby',
  'r/TooCuteForPorn',
  'r/Nofacemilfs',
  'r/FaceRatings',
  'r/Faces',
  'r/lipstickfetish2',
  'r/lips',
  'r/RealGirls',
  'r/GoneMild',
  'r/palegirls ',
  'r/stripchat_models',
  'r/sexygirlslk',
  'r/prettyaltgirls',
  'r/amihot',
  'r/gothsluts',
  'r/SFWRedheads',
  'r/Blowjobs',
  'r/cumpilation_love',
  'r/TeenFacialCumpilation',
  'r/facialcumpilation',
  'r/bigtiddygothgf',
  'r/AdorableOnlyfans',
  'r/naughtychicks ',
  'r/dirtysmall',
  'r/JizzedToThis',
  'r/UpskirtPanties',
  'r/downblouse',
  'r/GoneWildPlus ',
  'r/datgap',
  'r/cumfetish',
  'r/holdthemoan',
  'r/HappyEmbarrassedGirls',
  'r/ShemalesCock',
  'r/ShemalesParadise',
  'r/tscum',
  'r/traps',
  'r/FemBoys',
  'r/Tgirls',
  'r/GoneWildTrans',
  'r/transporn',
  'r/Shemales',
  'r/bigdickgirl',
  'r/TSonFM',
  'r/TransTease',
  'r/OnlyIfShesPackin',
  'r/Shemale_Big_Cock',
  'r/tbulges',
  'r/Ladyboys',
  'r/StraightToSissy',
  'r/SmallDickGirls',
  'r/ForcedFeminization',
  'r/Feminization',
  'r/Rearcock',
  'r/TransGoneWild',
  'r/trapsgonewild',
  'r/ShemaleCumHandsFree',
  'r/SissyInspiration',
  'r/TS_Domination ',
  'r/trapgifs',
  'r/Sissiesinaction',
  'r/HerCockHisAss',
  'r/asiansissification',
  'r/POVTranny',
  'r/Shemaleselffacials',
  'r/Trans_Cams',
  'r/LimpClittySissy',
  'r/ShemaleCumWhileFucked',
  'r/shemaleCumFromHandjob',
  'r/HandsFreeTrans',
  'r/ForeskinnyGirls',
  'r/perkydicks',
  'r/bigdickgothgirls',
  'r/TSNymphs',
  'r/DomRystan',
  'r/tgcumshots',
  'r/CutCockTGirls',
//'user/alizevert2',
  'r/NSFWFunny',
]);*/
const selectedBookmarks: Ref<string[]> = ref([bookmarks.value[0]])

export function useBookmarks() {

  const selectBookmark = (bookmark: string) => {
    selectedBookmarks.value = [bookmark]
  }

  const loadBookmark = (bookmark: string) => {
    selectBookmark(bookmark);
  }

  const selectRandom = () => {
    const rnd = Math.floor(Math.random() * (bookmarks.value.length - 1))
    selectBookmark(bookmarks.value[rnd]);
  }

  return {
    bookmarks,
    selectedBookmarks,
    selectBookmark,
    loadBookmark,
    selectRandom
  }
}
