<template>
  <div class="feed-viewer" ref="outer">
    <div class="inner-scroll" ref="inner">
      <PostItem v-for="(post, idx) in renderItems"
        :key="post.id"
        :post="post"
        :active="activePosts[idx]"
        :autoscroll="autoscroll"
        @item-ended="handleItemEnded"
        ref="postItems"
        @show-user-feed="(user) => $emit('showUserFeed', user)" />

      <div class="more">
        <LoadingIndicator v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Ref, computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import {Post, PostType, RedditScraper} from '../services/redditService';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PostItem from '@/components/PostItem.vue';
import { useBookmarks } from '@/composables/useBookmarks';

const { selectedBookmarks } = useBookmarks()
const outer = ref<HTMLDivElement>()
const inner = ref<HTMLDivElement>()

const props = defineProps({
  redditUrl: {
    type: String,
    required: true
  },
  name: String,
  autoscroll: {
    type: Boolean,
    default: false
  },
  filterMultiUserPosts: Boolean
});

const emit = defineEmits(['showUserFeed', 'itemEnded', 'endOfList'])

const isLoading = ref(false);

let reddit:  RedditScraper;
const posts:Ref<Post[]> = ref([])
const activePosts:Ref<Boolean[]> = ref([])
const precacheItems = 3;

let scrollTimeout: number;
let scrollEnded = true;

onMounted(async () => {
  handeRedditChange()
  outer.value!.addEventListener('scroll', handleScroll)
  outer.value!.addEventListener('scrollend', () => scrollEnded = true)
  outer.value!.addEventListener('touchstart', () => scrollEnded = false);
  outer.value!.addEventListener('touchend', () => scrollEnded = true);
})

onBeforeUnmount(() => {
  if (postItems.value[lastActivePost]) postItems.value[lastActivePost].stop();
  wasStopped.value = true;
  lastActivePost = 0
  outer.value?.removeEventListener('scroll', handleScroll);
  outer.value?.removeEventListener('scrollend', () => scrollEnded = true);
})

watch(() => props.redditUrl, () => {
  if (postItems.value[lastActivePost]) postItems.value[lastActivePost].stop();
  wasStopped.value = true;
  lastActivePost = 0
  handeRedditChange();
});

const handeRedditChange = async () => {
  posts.value = [];
  isLoading.value = true;
  reddit = new RedditScraper(props.redditUrl)

  await reddit.fetch();

  isLoading.value = false;
  posts.value = reddit.getResults();

  activePosts.value = Array.from(
    {length: posts.value.length},
    (_, i) => i<precacheItems
  );

  await nextTick()
  handleScrollEnd()
}

const handleItemEnded = () => {
  emit('itemEnded');
  if (props.autoscroll) {
    if (lastActivePost < postItems.value.length) {
      outer.value!.scrollTo({
        top: outer.value!.clientHeight * (lastActivePost + 1),
        left: 0,
        behavior: 'smooth'
      })
    } else {
      emit('endOfList');
    }
  }
}

// Handling Scroll play/stop & fetchMore
const postItems:Ref<typeof PostItem[]> = ref([])
let lastActivePost = 0;
const wasStopped = ref(false)

const handleScroll = () => {

  // for iOS
  if (!outer.value!.onscrollend) {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      handleScrollEnd();
    }, 200);
  }

  if (!wasStopped.value) {
    postItems.value[lastActivePost].stop();
    wasStopped.value = true;
  }
}

const handleScrollEnd = () => {
  if(!scrollEnded) return;
  const postNum = Math.floor(outer.value!.scrollTop / outer.value!.clientHeight)

  const activeStart = Math.max(0, postNum - precacheItems);
  const activeEnd = Math.min(postNum + precacheItems, postItems.value.length-1);
  activePosts.value = Array.from(
    { length: postItems.value.length },
    (_, i) => (i >= activeStart && i <= activeEnd)
  );

  lastActivePost = postNum;
  if (postItems.value[lastActivePost]) postItems.value[lastActivePost].play();
  wasStopped.value = false;

  if (postNum > renderItems.value.length - 3 && !isLoading.value) {
    console.log(`LoadMore, reached post ${postNum} of ${renderItems.value.length}`)
    loadMore()
  }
}


const renderItems = computed(() => {
  let items:Post[] = [];
  let lastPostTitle = '';
  posts.value.forEach((post) => {
    if (props.filterMultiUserPosts) {
      if (post.title == lastPostTitle) {
        return
      }
      lastPostTitle = post.title;
    }

    if (post.type == PostType.GALLERY) {
      let i = 1;
      post.gallery!.forEach(p => {
        const galItem = { ...post }
        galItem.title = `(${i++}/${post.gallery!.length}) ${galItem.title}`;
        galItem.fileUrl = p;
        galItem.type = PostType.IMAGE;
        items.push(galItem)
      })
    } else  {
      items.push(post);
    }
  });
  return items
});

const loadMore = async () => {
  isLoading.value = true;
  await reddit.nextPage();
  isLoading.value = false;
  posts.value = posts.value.concat(reddit.getResults())
}

</script>

<style lang="scss" scoped>
.feed-viewer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  overflow-y: auto;
  scroll-snap-type: y mandatory;

}

.more {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  div{
    text-align: center;
  }
}
</style>

