<template>
  <div class="home">
    <div class="play">
      <a href="#" class="playRandom" @click.prevent="randomReddit">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z"
              fill="#ffffff"></path>
          </g>
        </svg>
      </a>
    </div>
    <div class="search">
      <input placeholder="Search" v-model="search">
    </div>
    <div class="subreddit-list">
      <div v-for="reddit in filteredList" :key="reddit"
        @click.prevent="startFeed(reddit)"
        class="list-item"
        :style="getListItemStyle(reddit)">
        <span class="avatar" :style="getAvatarStyle(reddit)">
          <span v-if="getAvatarStyle(reddit).backgroundImage == 'none'">r/{{ reddit.substring(2,3).toUpperCase() }}</span>
        </span> <br>
        {{ reddit }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBookmarks } from '@/composables/useBookmarks';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import infos from '@/data/subreddit-infos.json';

const router = useRouter()
const {bookmarks, selectBookmark, selectRandom} = useBookmarks()
const search = ref('');

const filteredList = computed(() => {
  return search.value.length > 0
    ? bookmarks.value.filter( (s:string) => s.toLowerCase().indexOf(search.value.toLocaleLowerCase()) > -1)
    : bookmarks.value
})

const randomReddit = () => {
  selectRandom();
  router.push({ name: "feed" });
}

const startFeed = (reddit:string) => {
  selectBookmark(reddit)
  router.push({name: "feed"});
}

const getAvatarStyle = (reddit:string) => {
  const style = { backgroundImage: "none", backgroundColor: '#F74615' };
  if (Object.keys(infos).includes(reddit)){
    // @ts-ignore
    const img = infos[reddit].data?.community_icon;
    if(img != undefined && img != '') {
      style.backgroundImage = `url(${img.split('?')[0]})`
    } else {
      // @ts-ignore
      const color = infos[reddit].data?.primary_color;
      if (color != undefined && color != '') style.backgroundColor = color;
    }
  }
  return style;
}

const getListItemStyle = (reddit:string) => {
  const style = {backgroundImage: "none", backgroundColor: '#111111'};
  if (Object.keys(infos).includes(reddit)) {
    // @ts-ignore
    const img = infos[reddit].data?.community_icon;
    if (img != undefined && img != '') {
       style.backgroundImage = `url(${img.split('?')[0]})`
    } else {
      // @ts-ignore
      const color = infos[reddit].data?.key_color;
      if (color != undefined && color != '') style.backgroundColor = color;
    }
  }
  return style;
}

</script>

<style scoped lang="scss">
  .play {
    text-align: center;
    margin: 5rem auto 2rem;

    .playRandom{
      transition: all .1s linear;
      &:hover {
        opacity: .6;
      }
      svg {
        height: 120px;
      }
    }
  }

  .search {
    padding: 0 1rem;

    input {
      background-color: #111;
      border: 1px solid #333;
      border-radius: 5px;
      font-size: 1.4rem;
      padding: .4rem .8rem;
      width: 100%;
      box-sizing: border-box;
      color: #CCC;
    }
  }

  .subreddit-list {
    display: grid;
    grid-template-columns: auto auto;
    padding: 1rem;
    gap: 1rem;


    @media screen and (min-width: 1000px) {
      grid-template-columns: auto auto auto;
    }

    .list-item {
      background-color: #111;
      border-radius: 6px;
      padding: 4rem 2rem;
      text-align: center;
      cursor: pointer;
      transition: all .1s linear;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-shadow: 1px 0 5px #00000099, -1px 0 5px #00000099, 0 1px 5px #00000099, 0 -1px 5px #00000099;

      @media screen and (min-width: 1000px) {
        font-size: 2rem;
      }

      &:hover {
        background-color: #222;
      }

      .avatar {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #F74615;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        span {
          display: inline-block;
          padding-top: 5px;
          font-size: 1.8rem;
          font-weight: bold;
        }
      }
    }
  }
</style>
