<template>
  <div class="item" @click="console.log(post.raw)">

    <ImagePlayer v-if="active && post.type == PostType.IMAGE" :post="post" @ended="handleItemEnded"
      :autoscroll="autoscroll" ref="itemPlayer" />
    <VideoPlayer v-if="active && post.type == PostType.VIDEO" :post="post" @ended="handleItemEnded"
      :autoscroll="autoscroll" ref="itemPlayer" />

    <div class="labels">
      <h4>{{ post.subreddit }}</h4>
      <h2>{{ post.title }}</h2>
      <h3 @click="showAuthorFeed(post.author)">/u/{{ post.author }}</h3>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Post, PostType } from "../services/redditService";
import ImagePlayer from "@/components/ImagePlayer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";

const props = defineProps<{
  post: Post,
  active: Boolean,
  autoscroll: Boolean
}>()

const itemPlayer = ref<typeof VideoPlayer>();

const emit = defineEmits(['showUserFeed', 'itemEnded'])

const handleItemEnded = () => {
  emit('itemEnded')
}

const play = () => {
  //if(props.post.type == PostType.VIDEO) {
   if (props.active) itemPlayer.value!.play();
  //}
}
const stop = () => {
  //if (props.post.type == PostType.VIDEO) {
  if (props.active) itemPlayer.value!.stop();
  //}
}


defineExpose({
  play,
  stop
})


const showAuthorFeed = (author:string) => {
  emit('showUserFeed', author)
}
</script>

<style lang="scss" scoped>
  .item {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: #060610;
    scroll-snap-align: start;
    position: relative;


    .labels {
      position: absolute;
      bottom: 70px; //calc(15px + env(safe-area-inset-bottom));
      left: 20px;
      max-width: 500px;
      color: #FFFFFF;
      text-shadow: 0 0 8px #000000AA;
      text-align: left;

      h2, h3, h4 {
        margin: .2rem;
      }
      h3 {
        cursor: pointer;
      }
      h4 {
        font-weight: 300;
      }
    }
  }

</style>




