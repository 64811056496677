<template>
  <div class="image-player">
    <img v-if="showImage" class="main-image" :src="post.fileUrl" @load="onload" @error="onerror">
  </div>



  <div style="position: absolute; top: 0; background-color: #00000060; padding: 10px;" ref="logcont">

  </div>


  <div v-if="isLoading || hasError" class="overlay">
    <LoadingIndicator v-if="isLoading" />
    <div v-if="hasError" class="errorcont">Could not load:<br> <a :href="post.fileUrl" target="_blank">{{ post.fileUrl }}</a></div>
  </div>
  <div class="controls">
    <ProgressBar v-if="isPlaying && (autoscroll || (isAnimatedGif && !isLoading)) && imageTime>0" :timer="imageTime" />
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, onMounted, ref, watch } from 'vue';
import { Post,PostType } from "../services/redditService";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import axios, { AxiosError } from 'axios';
import apiService from '@/services/apiService';

const props = defineProps<{
  post: Post,
  autoscroll?: Boolean
}>()


// TMP LOGGING
const logcont = ref<HTMLDivElement>()
const ts = Date.now();
const log = (str:string) => {
  let s = ((Date.now() - ts) / 1000).toFixed(2);
  logcont.value!.innerHTML += s + "s " + str + "<br>";
}
// END TMP Logging

const emit = defineEmits(['ended'])
const isLoading = ref(true); // Disabled Load for Testing
const hasError = ref(false);
const isAnimatedGif = ref(false)
const isPlaying = ref(false)

let imageTime = ref(5000);
let timer: number

onMounted(async () => {
  if (/\.gif($|\?)/.test(props.post.fileUrl)) {
    imageTime.value = 0;
    isAnimatedGif.value = true;
    log('isAnimatedGif')
    isLoading.value = false;

    try {
      const d = await apiService.getGifDuration(props.post.fileUrl);
      imageTime.value = !d.duration ? 5000 : d.duration;
      log('Server Duration: ' + imageTime.value);
    } catch(e) {
      imageTime.value = 5000;
    }

  } else {
    isLoading.value = false;
  }

})

const showImage = computed(() => {
  return (!isAnimatedGif.value || (isAnimatedGif.value && !isLoading.value && isPlaying.value))
})



watch(() => props.autoscroll, (newVal) => {
  clearTimeout(timer)
  if (newVal) {
    startTimer();
  }
})



const startTimer = () => {
  timer = setTimeout(() => {
    emit('ended')
  }, imageTime.value);
}

const play = async () => {
  isPlaying.value = true;
  startTimer();
}
const stop = () => {
  isPlaying.value = false;
  clearTimeout(timer)
}

const togglePlay = () => {
  if (isPlaying.value) {
    stop()
  } else {
    play()
  }
}


const onload = () => {
  isLoading.value = false;
  hasError.value = false;
}

const onerror = () => {
  isLoading.value = false;
  hasError.value = true;
}

defineExpose({
  play,
  stop
})
</script>

<style lang="scss" scoped>
.image-player{
  width: 100vw;
  height: 100vh;
  text-align: center;

  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;

  .main-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}

.controls {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 20px;
  bottom: 60px; //calc(5px + env(safe-area-inset-bottom));
  height: 10px;
}

.overlay {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;

  .errorcont {
    text-align: center;

    a {
      color: #FFF;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }
}
</style>
